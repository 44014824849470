<template>
  <v-container
    id="portal-management"
    tag="section"
  >
    <base-material-card
      color="indigo"
      icon="mdi-bullseye"
      inline
      class="px-5 py-3"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
          Manage Portals
        </div>
      </template>
      <v-card :elevation="0" v-if="!isFrogAdmin && portalDetails !== {}">
        <v-card-title class="headline">
          Your Portal Information
        </v-card-title>
        <v-card-text>
          See the information and options for your portal.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <v-card-title>Page Headers</v-card-title>
          <v-row>
            <v-col cols="12" md="3" sm="6">
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title v-if="portalDetails.text.homePageHeader" v-text="portalDetails.text.homePageHeader"></v-list-item-title>
                    <v-list-item-title v-else>N/A</v-list-item-title>
                    <v-list-item-subtitle>Header text for Homepage</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title v-if="portalDetails.text.supportHeader" v-text="portalDetails.text.supportHeader"></v-list-item-title>
                    <v-list-item-title v-else>N/A</v-list-item-title>
                    <v-list-item-subtitle>Header text for Support Library Page</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title v-if="portalDetails.text.videoHeader" v-text="portalDetails.text.videoHeader"></v-list-item-title>
                    <v-list-item-title v-else>N/A</v-list-item-title>
                    <v-list-item-subtitle>Header text for Video Library Page</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title v-if="portalDetails.text.resourceHeader" v-text="portalDetails.text.resourceHeader"></v-list-item-title>
                    <v-list-item-title v-else>N/A</v-list-item-title>
                    <v-list-item-subtitle>Header text for Resource Library Page</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <v-card-title>Page Tag Lines</v-card-title>
          <v-row>
            <v-col cols="12" md="4" sm="6">
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title v-if="portalDetails.text.supportTagLine" v-text="portalDetails.text.supportTagLine"></v-list-item-title>
                    <v-list-item-title v-else>N/A</v-list-item-title>
                    <v-list-item-subtitle>Tag Line for Support Library</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="4" sm="6">
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title v-if="portalDetails.text.videoTagLine" v-text="portalDetails.text.videoTagLine"></v-list-item-title>
                    <v-list-item-title v-else>N/A</v-list-item-title>
                    <v-list-item-subtitle>Tag Line for Video Library Page</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="4" sm="6">
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title v-if="portalDetails.text.resourceTagLine" v-text="portalDetails.text.resourceTagLine"></v-list-item-title>
                    <v-list-item-title v-else>N/A</v-list-item-title>
                    <v-list-item-subtitle>Tag Line for Resource Library Page</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <v-card-title>Hero Image Tag Lines</v-card-title>
          <v-row>
            <v-col cols="12" md="4" sm="6">
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title v-if="portalDetails.text.topTagLine" v-text="portalDetails.text.topTagLine"></v-list-item-title>
                    <v-list-item-title v-else>N/A</v-list-item-title>
                    <v-list-item-subtitle>Upper Tag Line</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="4" sm="6">
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title v-if="portalDetails.text.mainTagLine" v-text="portalDetails.text.mainTagLine"></v-list-item-title>
                    <v-list-item-title v-else>N/A</v-list-item-title>
                    <v-list-item-subtitle>Main Tag Line</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="4" sm="6">
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title v-if="portalDetails.text.bottomTagLine" v-text="portalDetails.text.bottomTagLine"></v-list-item-title>
                    <v-list-item-title v-else>N/A</v-list-item-title>
                    <v-list-item-subtitle>Lower Tag Line</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <v-card-title>Top Pick Descriptors</v-card-title>
          <v-row>
            <v-col cols="12" md="4" sm="6">
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title v-if="portalDetails.text.topPickListings" v-text="portalDetails.text.topPickListings"></v-list-item-title>
                    <v-list-item-title v-else>N/A</v-list-item-title>
                    <v-list-item-subtitle>Tag Line for Top Picks - Listings</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="4" sm="6">
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title v-if="portalDetails.text.topPickVideos" v-text="portalDetails.text.topPickVideos"></v-list-item-title>
                    <v-list-item-title v-else>N/A</v-list-item-title>
                    <v-list-item-subtitle>Tag Line for Top Picks - Videos</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="4" sm="6">
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title v-if="portalDetails.text.topPickResources" v-text="portalDetails.text.topPickResources"></v-list-item-title>
                    <v-list-item-title v-else>N/A</v-list-item-title>
                    <v-list-item-subtitle>Tag Line for Top Picks - Resources</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <v-card-title>Call To Action</v-card-title>
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title v-if="portalDetails.text.ctaLink" v-text="portalDetails.text.ctaLink"></v-list-item-title>
                    <v-list-item-title v-else>N/A</v-list-item-title>
                    <v-list-item-subtitle>URL for CTA</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title v-if="portalDetails.text.ctaTitle" v-text="portalDetails.text.ctaTitle"></v-list-item-title>
                    <v-list-item-title v-else>N/A</v-list-item-title>
                    <v-list-item-subtitle>Text for CTA</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title v-if="portalDetails.text.ctaImage" v-text="portalDetails.text.ctaImage"></v-list-item-title>
                    <v-list-item-title v-else>N/A</v-list-item-title>
                    <v-list-item-subtitle>Image link for CTA background</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-img
                     :lazy-src="portalDetails.text.ctaImage"
                      max-height="150"
                      max-width="250"
                      :src="portalDetails.text.ctaImage"
                    ></v-img>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <v-card-title>External Link</v-card-title>
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title v-if="portalDetails.text.externalLinkText" v-text="portalDetails.text.externalLinkText"></v-list-item-title>
                    <v-list-item-title v-else>N/A</v-list-item-title>
                    <v-list-item-subtitle>Link text for the External Link</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title v-if="portalDetails.text.externalLinkURL" v-text="portalDetails.text.externalLinkURL"></v-list-item-title>
                    <v-list-item-title v-else>N/A</v-list-item-title>
                    <v-list-item-subtitle>URL for the External Link</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-row justify="center">
            <v-dialog
              v-model="requestDialog"
              overflow
              max-width="800px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="green darken-3"
                  v-bind="attrs"
                  v-on="on"
                >
                  Request a Change
                  <v-icon right>
                    mdi-help-circle
                  </v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>Select Request Type</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-select
                    :items="changeRequestTypeOptions"
                    v-model="selectedRequestType"
                    label="Select Request Type"
                  />
                </v-card-text>
                <v-divider></v-divider>
                <v-expand-transition>
                  <div v-show="selectedRequestType===1">
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-container>
                        <h3>Contact Email</h3>
                        <v-col cols="12">
                          <v-text-field
                            v-model="requestEmail"
                            :rules="[requestEmailRules.email, requestEmailRules.required]"
                            label="Email*"
                            validate-on-blur
                          />
                        </v-col>
                        <v-divider></v-divider>
                        <h3>Page Headers</h3>
                        <v-row>
                          <v-col cols="12" md="3" sm="6">
                            <v-text-field outlined v-model="requestText.homePageHeader" label="Home Page Header"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="3" sm="6">
                            <v-text-field outlined v-model="requestText.videoHeader" label="Video Page Header"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="3" sm="6">
                            <v-text-field outlined v-model="requestText.supportHeader" label="Support Page Header"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="3" sm="6">
                            <v-text-field outlined v-model="requestText.resourceHeader" label="Resource Page Header"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <h3>Page Tag Lines</h3>
                        <v-row>
                          <v-col cols="12" md="4" sm="6">
                            <v-text-field outlined v-model="requestText.videoTagLine" label="Video Tag Line"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4" sm="6">
                            <v-text-field outlined v-model="requestText.supportTagLine" label="Support Tag Line"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4" sm="6">
                            <v-text-field outlined v-model="requestText.resourceTagLine" label="Resource Tag Line"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <h3>Hero Tag Lines</h3>
                        <v-row>
                          <v-col cols="12" md="4" sm="6">
                            <v-text-field outlined v-model="requestText.topTagLine" label="Top Tag Line"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4" sm="6">
                            <v-text-field outlined v-model="requestText.mainTagLine" label="Main Tag Line"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4" sm="6">
                            <v-text-field outlined v-model="requestText.bottomTagLine" label="Bottom Tag Line"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <h3>Top Pick Descriptors</h3>
                        <v-row>
                          <v-col cols="12" md="4" sm="6">
                            <v-text-field outlined v-model="requestText.topPickVideos" label="Top Pick Videos"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4" sm="6">
                            <v-text-field outlined v-model="requestText.topPickListings" label="Top Pick Listings"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4" sm="6">
                            <v-text-field outlined v-model="requestText.topPickResources" label="Top Pick Resources"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <h3>External Link Details</h3>
                        <v-row>
                          <v-col cols="12" md="6" sm="6">
                            <v-text-field outlined v-model="requestText.externalLinkText" label="External Link Text"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6" sm="6">
                            <v-text-field outlined v-model="requestText.externalLinkURL" label="External Link URL"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <h3>Call To Action</h3>
                        <v-row>
                          <v-col cols="12" md="4" sm="6">
                            <v-text-field outlined v-model="requestText.ctaLink" label="CTA Link"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4" sm="6">
                            <v-text-field outlined v-model="requestText.ctaTitle" label="CTA Title"></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field outlined v-model="requestText.ctaImage" label="CTA Image"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                      <small>*indicates required field</small>
                    </v-card-text>
                  </div>
                </v-expand-transition>
                <v-expand-transition>
                  <div v-show="selectedRequestType===2">
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-container>
                        <v-card-text>
                          <h3>Contact Email</h3>
                          <v-col cols="12">
                            <v-text-field
                              v-model="requestEmail"
                              :rules="[requestEmailRules.email, requestEmailRules.required]"
                              label="Email*"
                              validate-on-blur
                            />
                          </v-col>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-text>
                          <h3>Please describe your changes</h3>
                          <v-row>
                            <v-col cols="12" sm="6">
                              <v-textarea outlined v-model="requestDesc" label="What changes would you like to see?"></v-textarea>
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-text>
                          <h3>Image Changes</h3>
                          <v-row>
                            <v-col cols="12" sm="6">
                              <v-text-field outlined v-model="requestImageLink" label="Provide a link to the desired image"></v-text-field>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-container>
                      <small>*indicates required field</small>
                    </v-card-text>
                  </div>
                </v-expand-transition>
                <v-card-actions>
                  <v-btn
                    color="red darken-1"
                    text
                    @click="requestDialog = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="sendRequest()"
                  >
                    Send Request
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </v-card-actions>
      </v-card>
      <v-card :elevation="0" v-if="isFrogAdmin" :loading="cardLoading">
        <v-card-title class="headline">
          Search for Portals
        </v-card-title>
        <v-card-text>
          Manage the different portals within Frog Systems
        </v-card-text>
        <v-card-text>
          <v-data-table
            :loading="isLoading"
            v-model="selected"
            :headers="headers"
            :items="portals"
            item-key="id"
            class="elevation-1"
            :disable-pagination="true"
            hide-default-footer
          >
            <template v-slot:top>
              <v-row>
                <v-col cols="12" sm="6" md="2" class="text-sm-center">
                  <v-dialog v-model="addPortalDialog" :retain-focus="false" max-width="800px" scrollable>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        class="mt-4 mr-0"
                      >
                        Create Portal
                      </v-btn>
                    </template>
                    <v-card :loading="cardLoading">
                      <v-card-title>
                        <span class="headline">Create Portal</span>
                      </v-card-title>
                      <v-card-text>
                        <v-expansion-panels focusable popout>
                          <v-expansion-panel>
                            <v-expansion-panel-header>Portal Settings</v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-container>
                                <v-row>
                                  <v-col cols="12" md="8" sm="6">
                                    <v-text-field v-model="newPortal.name" label="Portal Name*" required></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="4" sm="6">
                                    <v-checkbox
                                      v-model="newPortal.isPrivate"
                                      label="Private Portal"
                                    ></v-checkbox>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" md="4" sm="6">
                                    <v-text-field v-model="newPortal.url" label="Portal URL"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="4" sm="6">
                                    <v-text-field v-model="newPortal.googleAnalyticsTrackingID" label="Google Analytics Tracking ID"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="4" sm="6">
                                    <v-text-field v-model="newPortal.googleAnalyticsViewID" label="Google Analytics View ID"></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>Portal Text Options</v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-container>
                                <h4>Page Headers</h4>
                                <v-row>
                                  <v-col cols="12" md="3" sm="6">
                                    <v-text-field outlined v-model="newPortal.text.homePageHeader" label="Home Page Header"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="3" sm="6">
                                    <v-text-field outlined v-model="newPortal.text.videoHeader" label="Video Page Header"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="3" sm="6">
                                    <v-text-field outlined v-model="newPortal.text.supportHeader" label="Support Page Header"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="3" sm="6">
                                    <v-text-field outlined v-model="newPortal.text.resourceHeader" label="Resource Page Header"></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-divider></v-divider>
                                <h4>Page Tag Lines</h4>
                                <v-row>
                                  <v-col cols="12" md="4" sm="6">
                                    <v-text-field outlined v-model="newPortal.text.videoTagLine" label="Video Tag Line"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="4" sm="6">
                                    <v-text-field outlined v-model="newPortal.text.supportTagLine" label="Support Tag Line"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="4" sm="6">
                                    <v-text-field outlined v-model="newPortal.text.resourceTagLine" label="Resource Tag Line"></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-divider></v-divider>
                                <h4>Hero Tag Lines</h4>
                                <v-row>
                                  <v-col cols="12" md="4" sm="6">
                                    <v-text-field outlined v-model="newPortal.text.topTagLine" label="Top Tag Line"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="4" sm="6">
                                    <v-text-field outlined v-model="newPortal.text.mainTagLine" label="Main Tag Line"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="4" sm="6">
                                    <v-text-field outlined v-model="newPortal.text.bottomTagLine" label="Bottom Tag Line"></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-divider></v-divider>
                                <h4>Top Pick Descriptors</h4>
                                <v-row>
                                  <v-col cols="12" md="4" sm="6">
                                    <v-text-field outlined v-model="newPortal.text.topPickVideos" label="Top Pick Videos"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="4" sm="6">
                                    <v-text-field outlined v-model="newPortal.text.topPickListings" label="Top Pick Listings"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="4" sm="6">
                                    <v-text-field outlined v-model="newPortal.text.topPickResources" label="Top Pick Resources"></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-divider></v-divider>
                                <h4>External Link Details</h4>
                                <v-row>
                                  <v-col cols="12" md="6" sm="6">
                                    <v-text-field outlined v-model="newPortal.text.externalLinkText" label="External Link Text"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="6" sm="6">
                                    <v-text-field outlined v-model="newPortal.text.externalLinkURL" label="External Link URL"></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-divider></v-divider>
                                <h4>Call To Action</h4>
                                <v-row>
                                  <v-col cols="12" md="6" sm="6">
                                    <v-text-field outlined v-model="newPortal.text.ctaLink" label="CTA Link"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="6" sm="6">
                                    <v-text-field outlined v-model="newPortal.text.ctaTitle" label="CTA Title"></v-text-field>
                                  </v-col>
                                  <v-col cols="12">
                                    <v-text-field outlined v-model="newPortal.text.ctaImage" label="CTA Image"></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" v-on:click.stop="addPortalDialog = false">Nevermind</v-btn>
                        <v-btn :disabled="!newPortal.name" color="green darken-1" v-on:click.stop="createPortal()">Create</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select
                    :items="pageOptions"
                    v-model="rowsPerPage"
                    append-icon="mdi-chevron-down"
                    class="ml-auto"
                    label="Rows per Page"
                    hide-details
                    v-on:change="init()"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.isPrivate="{ item }">
              <v-tooltip v-if="item.isPrivate !== false" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    color="error"
                  >
                  mdi-eye-off-outline
                  </v-icon>
                </template>
                <span>Private Portal</span>
              </v-tooltip>
              <v-tooltip v-if="item.isPrivate === false" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    color="success"
                  >
                  mdi-eye-outline
                  </v-icon>
                </template>
                <span>Public Portal</span>
            </v-tooltip>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  v-on:click.stop="editPortal(item)"
                  v-bind="attrs"
                  v-on="on"
                  color="green darken-1"
                >
                mdi-pencil
                </v-icon>
                </template>
                <span>Edit Portal</span>
              </v-tooltip>
            </template>
            <template v-slot:no-data>
              <h2>Sorry, we couldn't find anything that matched that search.</h2>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-btn small rounded :disabled="(prevCursor === undefined || prevCursor === '') || isLoading === true" color="blue darken-1" v-on:click="previousPage()"><v-icon >mdi-chevron-left</v-icon></v-btn>
            <v-btn small rounded :disabled="(nextCursor === undefined || nextCursor === '') || isLoading === true" color="blue darken-1" v-on:click="nextPage()"><v-icon >mdi-chevron-right</v-icon></v-btn>
          </div>
        </v-card-text>
      </v-card>
      <v-snackbar v-model="snack" top right :timeout="snackTimeout" :color="snackColor">
        <v-progress-circular
          v-if="snackColor === 'info'"
          color="white"
          indeterminate
        ></v-progress-circular>
        {{ snackText }}
      </v-snackbar>
    </base-material-card>
  </v-container>
</template>

<script>
import GetPortals from '@/Services/Portals/GetPortals'
import CreatePortal from '@/Services/Portals/CreatePortal'
import RequestChange from '@/Services/Portals/RequestChange'
export default {
  name: 'PortalManagement',
  data: () => ({
    requestDesc: null,
    requestImageLink: null,
    requestText: {
      videoHeader: '',
      supportHeader: '',
      homePageHeader: '',
      resourceHeader: '',
      videoTagLine: '',
      supportTagLine: '',
      resourceTagLine: '',
      mainTagLine: '',
      topTagLine: '',
      bottomTagLine: '',
      topPickVideos: '',
      topPickListings: '',
      topPickResources: '',
      externalLinkText: '',
      externalLinkURL: '',
      ctaLink: '',
      ctaImage: '',
      ctaTitle: ''
    },
    requestEmail: null,
    requestEmailRules: {
      email: v => {
        if (v) {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(v) || 'Invalid e-mail.'
        }
      },
      required: v => !!v || 'Email is required'
    },
    requestDialog: false,
    selectedRequestType: null,
    changeRequestTypeOptions: [
      { value: null, text: 'Please select a request type' },
      { value: 1, text: 'Change Portal Text' },
      { value: 2, text: 'Any Other Changes' }
    ],
    nextCursor: null,
    prevCursor: null,
    rowsPerPage: 10,
    pageOptions: [5, 10, 20, 50, 100],
    addPortalDialog: false,
    newPortal: {
      name: '',
      isPrivate: false,
      url: '',
      googleAnalyticsTrackingID: null,
      googleAnalyticsViewID: null,
      text: {}
    },
    editDialog: false,
    editPortalObject: {
      name: '',
      text: {
        id: null,
        videoHeader: '',
        supportHeader: '',
        homePageHeader: '',
        resourceHeader: '',
        videoTagLine: '',
        supportTagLine: '',
        resourceTagLine: '',
        mainTagLine: '',
        topTagLine: '',
        bottomTagLine: '',
        topPickVideos: '',
        topPickListings: '',
        topPickResources: '',
        externalLinkText: '',
        externalLinkURL: '',
        ctaLink: '',
        ctaImage: '',
        ctaTitle: ''
      }
    },
    searchPortals: '',
    singleSelect: false,
    selected: [],
    portalDetails: null,
    isLoading: true,
    isFrogAdmin: null,
    model: null,
    portals: [],
    headers: [
      {
        text: 'ID',
        align: 'start',
        value: 'id'
      },
      { text: 'Name', value: 'name' },
      { text: 'URL', value: 'url' },
      { text: 'Google Tracking ID', value: 'googleAnalyticsTrackingID' },
      { text: 'Google View ID', value: 'googleAnalyticsViewID' },
      { text: 'Private', value: 'isPrivate' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    snack: false,
    snackColor: '',
    snackText: null,
    snackTimeout: null,
    cardLoading: false
  }),
  created () {
    const userRole = []
    this.$auth.user['https://userrolesfrog.admin/user_authorization'].roles.forEach(element => {
      userRole.push(element)
    })
    if (userRole.includes('Frog Admin')) {
      this.isFrogAdmin = true
    } else {
      this.isFrogAdmin = false
    }
    this.init()
  },
  methods: {
    async init () {
      this.isLoading = true
      if (!this.isFrogAdmin) {
        let userPortal = null
        userPortal = this.$auth.user['https://customclaim.com/portal']
        await GetPortals.getUserPortalDetails(userPortal)
          .then((res) => {
            if (res.status === 200) {
              this.$set(this, 'portalDetails', res.data)
              this.nextCursor = res.data.nextCursor
              this.prevCursor = res.data.previousCursor
              this.isLoading = false
            } else {
              this.snackError(res.status)
            }
          })
      } else if (this.isFrogAdmin) {
        await GetPortals.getPortals(this.rowsPerPage)
          .then((response) => {
            if (response.status === 200) {
              this.$set(this, 'portals', response.data.results)
              this.nextCursor = response.data.nextCursor
              this.prevCursor = response.data.previousCursor
              this.isLoading = false
            }
          })
      }
    },
    async previousPage () {
      this.isLoading = true
      this.snackLoading('Loading ...')
      await GetPortals.getPreviousPage(this.rowsPerPage, this.prevCursor)
        .then((res) => {
          if (res.status === 200) {
            this.$set(this, 'portals', res.data.results)
            this.nextCursor = res.data.nextCursor
            this.prevCursor = res.data.previousCursor
            this.isLoading = false
            this.snackSuccess('Done')
          } else {
            this.snackError('An error occurred. Try again later')
          }
        })
    },
    async nextPage () {
      this.isLoading = true
      this.snackLoading('Loading ...')
      await GetPortals.getNextPage(this.rowsPerPage, this.nextCursor)
        .then((res) => {
          if (res.status === 200) {
            this.$set(this, 'portals', res.data.results)
            this.nextCursor = res.data.nextCursor
            this.prevCursor = res.data.previousCursor
            this.isLoading = false
            this.snackSuccess('Done')
          } else {
            this.snackError('An error occurred. Try again later')
          }
        })
    },
    async getPortalDetails (item) {
      this.snackLoading('Loading ...')
      this.cardLoading = true
      await GetPortals.getPortalDetails(item.id)
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'portalDetails', response.data)
            this.cardLoading = false
            this.snackSuccess('Done')
          } else if (response.status === 204) {
            this.cardLoading = false
            this.snackError('No data returned')
          } else {
            this.cardLoading = false
            this.snackError('An error occurred. Try again later.')
          }
        })
    },
    async editPortal (item) {
      this.$router.push(`portal-management/${item.id}/${item.name}`)
    },
    async createPortal () {
      this.addPortalDialog = false
      this.snackLoading('Creating Portal ...')
      this.isLoading = true
      await CreatePortal.createPortal(this.newPortal)
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false
            this.snackSuccess('Portal Created')
            this.init()
          } else {
            this.isLoading = false
            this.snackError('An error occured. Try again later')
          }
        })
    },
    async sendRequest () {
      let userPortal = null
      userPortal = this.$auth.user['https://customclaim.com/portal']
      this.requestDialog = false
      this.snackLoading('Sending Request ...')
      if (this.selectedRequestType === 1) {
        const request = {
          text: this.requestText,
          email: this.requestEmail
        }
        await RequestChange.requestChangeType1(userPortal, request)
          .then((res) => {
            if (res.status === 202) {
              this.snackSuccess('Request Accepted')
              this.resetRequest()
              this.requestDialog = false
            } else {
              this.snackError('An error occurred. Try again later')
              this.resetRequest()
              this.requestDialog = false
            }
          })
      } else if (this.selectedRequestType === 2) {
        const request = {
          desc: this.requestDesc,
          imageLink: this.requestImageLink,
          email: this.requestEmail
        }
        await RequestChange.requestChangeType2(userPortal, request)
          .then((res) => {
            if (res.status === 202) {
              this.snackSuccess('Request Accepted')
              this.resetRequest()
              this.requestDialog = false
            } else {
              this.snackError('An error occurred. Try again later')
              this.resetRequest()
              this.requestDialog = false
            }
          })
      }
    },
    snackLoading (loadingText) {
      this.snack = true
      this.snackText = loadingText
      this.snackColor = 'info'
      this.snackTimeout = -1
    },
    snackSuccess (successText) {
      this.snack = true
      this.snackText = successText
      this.snackColor = 'success'
      this.snackTimeout = 3000
    },
    snackError (errorMessage) {
      this.snack = true
      this.snackText = errorMessage
      this.snackColor = 'error'
      this.snackTimeout = 5000
    },
    resetRequest () {
      this.requestDesc = null
      this.requestImageLink = null
      this.requestEmail = null
      this.requestText = {}
    }
  }
}
</script>
