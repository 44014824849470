<script>
import axios from 'axios'
import { getInstance } from '../../auth/index'
const authService = getInstance()
export default {
  async requestChangeType1 (id, request) {
    const token = await authService.getTokenSilently()
    const URL = process.env.VUE_APP_APIURL + 'portal-management/portals/' + id + '/change-request'
    var requestData = {
      requestType: 1,
      text: request.text,
      contactEmail: request.email
    }
    const ops = {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      data: requestData,
      url: URL
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      // eslint-disable-next-line
      console.error(error.response)
      return error.response
    }
  },
  async requestChangeType2 (id, request) {
    const token = await authService.getTokenSilently()
    const URL = process.env.VUE_APP_APIURL + 'portal-management/portals/' + id + '/change-request'
    var requestData = {
      requestType: 2,
      changeDescription: request.desc,
      imageLink: request.imageLink,
      contactEmail: request.email
    }
    const ops = {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      data: requestData,
      url: URL
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      // eslint-disable-next-line
      console.error(error.response)
      return error.response
    }
  }
}
</script>
